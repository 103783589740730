import React, { Component, useState, useEffect, useContext } from 'react'
import { useLocation, useNavigate, Link } from 'react-router-dom';
import Header from '../components/Header'
import Footer from '../components/Footer'
import IndicatorCompare from '../dashboard_components/IndicatorCompare'
import AreaCompare from '../dashboard_components/AreaCompare'
import FYCompare from '../dashboard_components/FYCompare'
import IndicatorCompareLineChart from '../dashboard_components/IndicatorCompareLineChart'
import IndicatorCompareBarChart from '../dashboard_components/IndicatorCompareBarChart'
import AreaCompareLineChart from '../dashboard_components/AreaCompareLineChart'
import FYCompareLineChart from '../dashboard_components/FYCompareLineChart'
import { fetchDataCompare, getIndicatorArray } from '../util.js'
import useAxios from './useAxios';
import { SkeletonIndiComp, SkeletonCompare } from "./SkeletonCard";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AuthContext from '../context/AuthContext';

export default function Compare() {

    const [level, setLevel] = useState("state");
    const [barChartData, setbarChartData] = useState([]);
    const [indicator, setIndicator] = useState([5]);
    const [fyear, setFyear] = useState([3]);
    const [month, setMonth] = useState(13);
    const [state, setState] = useState([1]);
    const [district, setDistrict] = useState([774]);
    const [area, setArea] = useState([1]);
    const [areaType, setAreaType] = useState('State');
    const [indicatorType, setIndicatorType] = useState('positive');
    const [indicatorCategory, setIndicatorCategory] = useState(1);
    const [areaDetails, setAreaDetails] = useState([]);
    const [stateDropdown, setStateDropdown] = useState([]);
    const [districtDropdown, setDistrictDropdown] = useState([]);
    const [fyDropdown, setFYDropdown] = useState([]);
    const [monthDropdown, setMonthDropdown] = useState([]);
    const [indicatorDropdown, setIndicatorDropdown] = useState([]);
    const location = useLocation();
    const [lineChartData, setLineChartData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [qualityData, setQualityData] = useState([]);
    const [toggleState, setToggleState] = useState(true);

    const [results, setResults] = useState([]);
    const [status, setStatus] = useState("indicator")
    const [isSelected, setIsSelected] = useState(true);
    const [toggleValue, setToggleValue] = useState('percent');
    const [found, setFound] = useState(true);
    let { user } = useContext(AuthContext);
    let areaStateId = user.state;
    const api = useAxios();
    let SkeletonScreen;

    const onChangeValue = async (e) => {
        setIsSelected(false);
        const statusVal = e.target.value;

        setFyear([3]);
        setMonth([13]);
        setArea([1]);
        setIndicator([5]);
        setState([1]);
        setDistrict([774]);
        setAreaType('State');
        let fyReset = [3];
        let areaReset = [1];
        let indicatorReset = [5];
        let monthReset = 13;
        let areaTypeReset = 'State';
        let indicator_cat = 1;
        setStatus(statusVal);

        await fetchDataCompare(fyReset, monthReset, areaReset, indicatorReset, areaTypeReset, indicator_cat, statusVal, setbarChartData, setLineChartData, setTableData, setQualityData, api);
        setIsSelected(true);
    }

    useEffect(() => {
        setIsSelected(false);
        async function getInitialData() {
            const [response1, response2, response3, response4] = await Promise.all([
                api.get(`/ina_area/${areaStateId}`),
                api.get('/fy'),
                api.get('/month'),
                api.get('/indicators')
            ]);

            let areaData = response1.data.filter(f => (f.area_level != 5));
            setAreaDetails(areaData);
            let stateData = areaData.filter(f => (f.area_type === 'State'));
            let districtData = areaData.filter(f => (f.area_type === 'District') & (f.area_id != 774));
            setStateDropdown(stateData);
            setDistrictDropdown(districtData);


            let fyData = response2.data.filter(m => (m.fy_id < 5));
            setFYDropdown(fyData);

            let monthData = response3.data.filter(m => (m.month_id < 14));
            setMonthDropdown(monthData);

            let indicatorData = response4.data.filter(i => (i.indicator_id < 37 && i.indicator_id != 29));
            setIndicatorDropdown(indicatorData);

            await fetchDataCompare(fyear, month, area, indicator, areaType, indicatorCategory, status, setbarChartData, setLineChartData, setTableData, setQualityData, api);
            setIsSelected(true);
        }
        getInitialData();

    }, []);

    const ToggleButtonChange = async (event, newAlignment) => {
        setIsSelected(false);
        setToggleValue(newAlignment);
        let indicator_values = indicator;
        if (newAlignment === 'count') {
            let indiArray = getIndicatorArray(indicatorDropdown, indicator_values, newAlignment);
            if (indiArray.length === 0) {
                setFound(false);
            }
            else {
                let indicatorSelected = indiArray.join(',');
                setFound(true);
                await fetchDataCompare(fyear, month, area, indicatorSelected, areaType, indicatorCategory, status, setbarChartData, setLineChartData, setTableData, setQualityData, api);
            }

        } else {
            let indiArray = getIndicatorArray(indicatorDropdown, indicator_values, newAlignment);
            if (indiArray.length === 0) {
                setFound(false);
            }
            else {
                let indicatorSelected = indiArray.join(',');
                setFound(true);
                await fetchDataCompare(fyear, month, area, indicatorSelected, areaType, indicatorCategory, status, setbarChartData, setLineChartData, setTableData, setQualityData, api);
            }

        }
        setIsSelected(true);
    };

    const theme = createTheme({
        components: {
            MuiToggleButton: {
                styleOverrides: {
                    root: {
                        border: `1px solid #000`,
                        color: '#000',
                        fontWeight: 'bold',
                    },
                },
            },
        },
    });

    let ToggleButtonSwitch = <ThemeProvider theme={theme}>
        <ToggleButtonGroup
            color="info"
            value={toggleValue}
            fullWidth={true}
            exclusive
            onChange={ToggleButtonChange}
            aria-label="Platform"
            variant="contained"
        >
            <ToggleButton value="percent">Percent</ToggleButton>
            <ToggleButton value="count">Count</ToggleButton>
        </ToggleButtonGroup>
    </ThemeProvider>


    if (status === 'fy' || status === 'area') {
        SkeletonScreen = <SkeletonCompare />;
    } else {
        SkeletonScreen = <SkeletonIndiComp />;
    }

    return (
        <React.Fragment>
            <Header />

            <div class="container-fluid">
                <div class="row">
                    {/* <SideMenu /> */}
                    <main class="col hmis-dashboard-main md:mx-2.5">
                        <div class="container-2xl m-0">
                            <div class="flex md:flex-wrap flex-wrap md:mb-3 md:px-1">
                                <div class="flex w-full md:w-3/4">
                                    <h2 class="text-3xl font-bold text-black md:text-2xl lg:text-3xl">HMIS Dashboard <span class="hmis-beta">Beta</span></h2>
                                </div>
                                <div class="flex flex-row w-full md:w-1/4 justify-center">
                                    {/* <Link to="#">
                                        <button className="bg-cyan-900 hover:bg-gray-900 text-white font-bold text-md mb-1 py-2 px-4 mt-1 rounded-full">Cumulative</button>
                                    </Link> */}
                                    <Link to="/dashboard">
                                        <button className="bg-cyan-900 hover:bg-gray-900 text-white font-bold text-md mb-1 ml-3 py-2 px-4 mt-1 rounded-full">Back</button>
                                    </Link>
                                </div>
                            </div>

                            <div class="flex flex-col flex-wrap md:flex-row mx-3" onChange={onChangeValue}>
                                <div class="flex w-full md:w-1/2 lg:w-1/3">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="indicator" checked={status === "indicator"} />
                                    <label class="ml-2 text-base font-medium text-black" for="inlineRadio1">Indicator wise Comparison</label>
                                </div>
                                <div class="flex w-full md:w-1/2 lg:w-1/3">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="fy" checked={status === "fy"} />
                                    <label class="ml-2 text-base font-medium text-black" for="inlineRadio2">Financial Year wise Comparison</label>
                                </div>
                                <div class="flex w-full md:w-1/2 lg:w-1/3">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="area" checked={status === "area"} />
                                    <label class="ml-2 text-base font-medium text-black" for="inlineRadio3">Area wise Comparison</label>
                                </div>
                            </div>

                            {status === "indicator" ?
                                <IndicatorCompare fyear={fyear}
                                    state={state}
                                    district={district}
                                    month={month}
                                    area={area}
                                    indicator={indicator}
                                    areaType={areaType}
                                    indicatorCategory={indicatorCategory}
                                    stateDropdown={stateDropdown}
                                    districtDropdown={districtDropdown}
                                    fyDropdown={fyDropdown}
                                    monthDropdown={monthDropdown}
                                    indicatorDropdown={indicatorDropdown}
                                    setAreaType={setAreaType}
                                    setIndicator={setIndicator}
                                    setArea={setArea}
                                    setMonth={setMonth}
                                    setFyear={setFyear}
                                    setState={setState}
                                    setDistrict={setDistrict}
                                    status={status}
                                    setbarChartData={setbarChartData}
                                    setLineChartData={setLineChartData}
                                    setTableData={setTableData}
                                    setQualityData={setQualityData}
                                    isSelected={isSelected}
                                    setIsSelected={setIsSelected}
                                    toggleValue={toggleValue}
                                    setFound={setFound}
                                /> : null}

                            {status === "area" ?
                                <AreaCompare fyear={fyear}
                                    state={state}
                                    district={district}
                                    month={month}
                                    area={area}
                                    indicator={indicator}
                                    areaType={areaType}
                                    indicatorCategory={indicatorCategory}
                                    stateDropdown={stateDropdown}
                                    districtDropdown={districtDropdown}
                                    fyDropdown={fyDropdown}
                                    indicatorDropdown={indicatorDropdown}
                                    setAreaType={setAreaType}
                                    setIndicator={setIndicator}
                                    setArea={setArea}
                                    setMonth={setMonth}
                                    setFyear={setFyear}
                                    setState={setState}
                                    setDistrict={setDistrict}
                                    level={level}
                                    setLevel={setLevel}
                                    status={status}
                                    setbarChartData={setbarChartData}
                                    setLineChartData={setLineChartData}
                                    setTableData={setTableData}
                                    setQualityData={setQualityData}
                                    isSelected={isSelected}
                                    setIsSelected={setIsSelected}
                                    toggleValue={toggleValue}
                                    setFound={setFound}
                                /> : null}

                            {status === "fy" ?
                                <FYCompare fyear={fyear}
                                    state={state}
                                    district={district}
                                    month={month}
                                    area={area}
                                    indicator={indicator}
                                    areaType={areaType}
                                    indicatorCategory={indicatorCategory}
                                    stateDropdown={stateDropdown}
                                    districtDropdown={districtDropdown}
                                    fyDropdown={fyDropdown}
                                    indicatorDropdown={indicatorDropdown}
                                    setAreaType={setAreaType}
                                    setIndicator={setIndicator}
                                    setArea={setArea}
                                    setMonth={setMonth}
                                    setFyear={setFyear}
                                    setState={setState}
                                    setDistrict={setDistrict}
                                    status={status}
                                    setbarChartData={setbarChartData}
                                    setLineChartData={setLineChartData}
                                    setTableData={setTableData}
                                    setQualityData={setQualityData}
                                    isSelected={isSelected}
                                    setIsSelected={setIsSelected}
                                    toggleValue={toggleValue}
                                    setFound={setFound}
                                /> : null}

                            <div class="flex my-2 md:my-0 md:px-1 items-center">
                                <div className='flex w-full lg:w-1/5 justify-center'>
                                    <div className='w-11/12'>
                                        {ToggleButtonSwitch}
                                    </div>
                                </div>
                            </div>

                            {(isSelected || indicator.length === 0 || fyear.length === 0 || area.length === 0) ? (
                                <section id='main_dashboard_container' className='flex flex-col'>

                                    <section
                                        id='indicator_compare'
                                        className='flex md:flex-wrap flex-wrap justify-center items-center'>

                                        {(fyear != 'All' && area < 39) ?
                                            (<div className='flex w-full flex-wrap'>
                                                <div className='flex w-full md:w-1/2 justify-center items-center'>
                                                    {(status === "indicator") ? ((found && lineChartData != null && lineChartData != '') ?
                                                        <IndicatorCompareLineChart
                                                            lChartData={lineChartData}
                                                            indicator={indicator}
                                                            fyear={fyear}
                                                            toggleValue={toggleValue}
                                                            indicatorDropdown={indicatorDropdown}
                                                        /> : <div className='flex text-xl font-bold text-black'>Data Not Available</div>)
                                                        : null}
                                                </div>
                                                <div className='flex w-full md:w-1/2 justify-center items-center'>
                                                    {(status === "indicator") ? ((found && barChartData != null && barChartData != '') ?
                                                        <IndicatorCompareBarChart
                                                            barChartData={barChartData}
                                                            indicator={indicator}
                                                            toggleValue={toggleValue}
                                                            indicatorDropdown={indicatorDropdown}
                                                        /> : <div className='flex text-xl font-bold text-black'>Data Not Available</div>)
                                                        : null}
                                                </div>
                                            </div>
                                            ) :
                                            <div className='flex w-full md:w-9/12 justify-center items-center'>
                                                {(status === "indicator") ? ((found && lineChartData != null && lineChartData != '') ?
                                                    <IndicatorCompareLineChart
                                                        lChartData={lineChartData}
                                                        indicator={indicator}
                                                        fyear={fyear}
                                                        toggleValue={toggleValue}
                                                        indicatorDropdown={indicatorDropdown}
                                                    /> : <div className='flex text-xl font-bold text-black'>Data Not Available</div>)
                                                    : null}
                                            </div>
                                        }
                                    </section>

                                    <section id='fy_area_compare'>
                                        <div className='flex w-full md:w-full justify-center items-center' >
                                            {(status === "area") ? ((found && lineChartData != null && lineChartData != '') ?
                                                <AreaCompareLineChart
                                                    lChartData={lineChartData}
                                                    area={area}
                                                    fyear={fyear}
                                                    toggleValue={toggleValue}
                                                /> : <div className='flex text-xl font-bold text-black'>Data Not Available</div>) : null}

                                            {(status === "fy") ? ((found && lineChartData != null && lineChartData != '') ?
                                                <FYCompareLineChart
                                                    lChartData={lineChartData}
                                                    fyear={fyear}
                                                    toggleValue={toggleValue}
                                                /> : <div className='flex text-xl font-bold text-black'>Data Not Available</div>) : null}
                                        </div>
                                    </section>
                                </section>
                            ) : (
                                SkeletonScreen
                            )}

                        </div>

                        <>
                            <Footer />
                        </>
                    </main>
                </div>
            </div>
        </React.Fragment>
    )

}