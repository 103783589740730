import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import Home from './Home';
import Dashboard from './Dashboard';
import Compare from './Compare';
import PrivateRoute from './PrivateRoute';
import { AuthProvider } from "../context/AuthContext";
import Login from "../components/Login";

export default function RouterPage() {
    return (
        <div>
            <Router>
                <AuthProvider>
                    <Routes>
                        <Route exact path='/' element={<Home />} />
                        <Route path="/login" exact element={<Login />} />
                        <Route exact path='/' element={<PrivateRoute />}>
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/compare" element={<Compare />} />

                            {/* Dynamic Route for Dashboard Page */}
                            <Route path="dashboard/:indicator" element={<Dashboard />} />

                            {/* Routers for dashboard page */}
                            <Route path="/dashboard/pregnancies" element={<Dashboard />} />
                        </Route>
                    </Routes>
                </AuthProvider>
            </Router>
        </div>
    )
}