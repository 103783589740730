import React from "react";
// import "./SkeletonCard.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {
    Grid,
    Box,
} from '@material-ui/core';
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonQuadrant = () => {
    return (
        <Box p={2} lg={12} >
            <Grid lg={12} item container spacing={2} padding={2}>
                <Grid item lg={6} xs={12} sm={6} zeroMinWidth>
                    <SkeletonTheme color="grey" highlightColor="#444">
                        <Skeleton variant="rounded" animation="wave" duration={4} height={400} />
                    </SkeletonTheme>
                </Grid>
                <Grid item lg={6} xs={12} sm={6}>
                    <SkeletonTheme color="grey" highlightColor="#444">
                        <Skeleton variant="rounded" animation="wave" duration={4} height={400} />
                    </SkeletonTheme>
                </Grid>
                <Grid item lg={6} xs={12} sm={6}>
                    <SkeletonTheme color="grey" highlightColor="#444">
                        <Skeleton variant="rounded" animation="wave" duration={4} height={400} />
                    </SkeletonTheme>
                </Grid>
                <Grid item lg={6} xs={12} sm={6}  >
                    <SkeletonTheme color="grey" highlightColor="#444">
                        <Skeleton variant="rounded" animation="wave" duration={4} height={400} />
                    </SkeletonTheme>
                </Grid>
            </Grid>
        </Box>
    );
};

const SkeletonIndiComp = () => {
    return (
        <Box lg={12}>
            <Grid lg={12} item container spacing={2} padding={2}>
                <Grid item lg={6} xs={12} sm={6} zeroMinWidth>
                    <SkeletonTheme color="grey" highlightColor="#444">
                        <Skeleton variant="rounded" animation="wave" duration={4} height={400} />
                    </SkeletonTheme>
                </Grid>
                <Grid item lg={6} xs={12} sm={6}>
                    <SkeletonTheme color="grey" highlightColor="#444">
                        <Skeleton variant="rounded" animation="wave" duration={4} height={400} />
                    </SkeletonTheme>
                </Grid>
            </Grid>
        </Box>
    );
};

const SkeletonCompare = () => {
    return (
        <Box lg={12}>
            <Grid lg={12} item container spacing={2} padding={2}>
                <Grid item lg={12} xs={12} sm={12} zeroMinWidth>
                    <SkeletonTheme color="grey" highlightColor="#444">
                        <Skeleton variant="rounded" animation="wave" duration={4} height={800} />
                    </SkeletonTheme>
                </Grid>
            </Grid>
        </Box>
    );
};
export { SkeletonQuadrant, SkeletonIndiComp, SkeletonCompare };