import _ from 'lodash';
import fmt from 'indian-number-format';
import { min, max } from 'd3';

export async function fetchData(fyear, month, area, indicator, area_type, indicatorCategory, setbarChartData, setLineChartData, setTableData, setQualityData, api, logoutUser) {
  const [response1, response2, response3, response4] = await Promise.all([
    api.get(`/indiaAllData/${area}/${fyear}/${month}/${area_type}/${indicator}`),
    api.get(`/indiaAllLine/${area}/${fyear}/${indicator}`),
    api.get(`/indiaTableData/${area}/${fyear}/${month}/${indicatorCategory}`),
    api.get(`/inaDataQualityParameters/${area}/${fyear}/${month}/${indicator}`)
  ]);
  if (response1.status === 200) {
    setbarChartData(response1.data);
    setLineChartData(response2.data);
    setTableData(response3.data);
    setQualityData(response4.data);
  } else if (response1.statusText === 'Unauthorized') {
    logoutUser()
  }
}

export async function fetchDataCompare(fyear, month, area, indicator, area_type, indicatorCategory, status, setbarChartData, setLineChartData, setTableData, setQualityData, api) {
  if (status === 'indicator') {
    const [response1, response2] = await Promise.all([
      api.get(`/indiaAllData/${area}/${fyear}/${month}/${area_type}/${indicator}`),
      api.get(`/indiaAllLine/${area}/${fyear}/${indicator}`)
    ]);

    setLineChartData(response2.data);
    setbarChartData(response1.data);
  }
  else {
    const response2 = await api.get(`/indiaAllLine/${area}/${fyear}/${indicator}`)
    setLineChartData(response2.data);
  }
}

export function createTableData(data) {
  const uniqueIndicators = [...new Set(data.map(item => item.indicator.indicator_id))];
  const uniqueArea = [...new Set(data.map(item => item.area.area_name))];
  let area_all = [];
  for (var i = 0; i < uniqueArea.length; i++) {
    const dt = data.filter(e => e.area.area_name == uniqueArea[i] && uniqueIndicators.includes(e.indicator.indicator_id))
    let all_data = [...new Set(dt.map(item => ({ indicator: item.indicator, numeric_value: item.num_data_value, percent_value: item.per_data_value })))];

    area_all.push({
      area: uniqueArea[i],
      indDetails: all_data
    });
  }
  return area_all;
}

export function createPercentArray(data) {
  const uniqueIndicators = [...new Set(data.map(item => item.indicator.indicator_id))];
  let newData = [];
  let percentArray = [];
  for (var i = 0; i < uniqueIndicators.length; i++) {
    let indiInfo = data.filter(f => f.indicator.indicator_id === uniqueIndicators[i]);
    if (indiInfo[0].indicator.variant === 'Both' || indiInfo[0].indicator.variant === 'Percent') {
      percentArray = indiInfo.map(a => a.per_data_value);

      if (indiInfo[0].indicator.type === "positive") {
        percentArray = indiInfo.filter(function (a) { return a.per_data_value < 100; }).map(function (a) { return a.per_data_value; })
      }
      newData.push({
        indicatorId: uniqueIndicators[i],
        max: max(percentArray),
        min: min(percentArray)
      });
    }
  }
  return newData;
}


export function createQualityData(qualityData) {

  let zero_count = qualityData.zeroReportedBlocks ? qualityData.zeroReportedBlocks.length : 0,
    null_count = qualityData.nullReportedBlocks ? qualityData.nullReportedBlocks.length : 0,
    outlier_count = qualityData.outlierReportedBlocks ? qualityData.outlierReportedBlocks.length : 0,
    duplicate_count = qualityData.duplicateReportedBlocks ? qualityData.duplicateReportedBlocks.length : 0;

  let count_values = [{ zero_count: zero_count, null_count: null_count, outlier_count: outlier_count, duplicate_count: duplicate_count }]

  qualityData = [qualityData];

  const data = qualityData.map(item => ({
    zeroBlockList: item.zeroReportedBlocks.map(role => role.area__area_name),
    nullBlockList: item.nullReportedBlocks.map(role => role.area__area_name),
    outlierBlockList: item.outlierReportedBlocks.map(role => role.area__area_name),
    duplicateBlockList: item.duplicateReportedBlocks.map(role => role.area__area_name),
  }))

  let zeroBlockList1 = data[0].zeroBlockList;
  let nullBlockList1 = data[0].nullBlockList;
  let outlierBlockList1 = data[0].outlierBlockList;
  let duplicateBlockList1 = data[0].duplicateBlockList;
  let rows = _.zip(zeroBlockList1, nullBlockList1, outlierBlockList1, duplicateBlockList1);
  return [count_values, rows];

};

export function getColorArray(data, colorLow, colorMid, colorHigh, minVal, maxVal, toggleValue) {
  var colors = [];
  if (toggleValue === 'percent') {
    let low = minVal;
    let high = maxVal;
    if (colorHigh === '#30A054') {
      for (var i = 0; i < data.length; i++) {
        let val = data[i];
        if (val < low) {
          colors.push(colorLow);
        } else if (val >= low && val < high) {
          colors.push(colorMid);
        } else if (val >= 100) {
          colors.push('#006837');
        }
        else {
          colors.push(colorHigh);
        }
      }
      return colors;
    } else {
      for (var i = 0; i < data.length; i++) {
        var val = data[i];
        if (val < low) {
          colors.push(colorLow);
        } else if (val >= low && val <= high)
          colors.push(colorMid);
        else if (val > high) {
          colors.push(colorHigh);
        }
      }
      return colors;
    }
  } else {
    if (colorHigh === '#30A054') {
      let min = Math.min(...data)
      let max = Math.max(...data)
      let comp = (max - min) / 3;
      let low = min + comp;
      let high = max - comp;

      for (var i = 0; i < data.length; i++) {
        let val = data[i];
        if (val < low) {
          colors.push(colorLow);
        } else if (val >= low && val < high) {
          colors.push(colorMid);
        } else if (val >= 100) {
          colors.push('#006837');
        }
        else {
          colors.push(colorHigh);
        }
      }
      return colors;
    } else {
      let min = Math.min(...data)
      let max = Math.max(...data)
      let comp = (max - min) / 3;
      let low = min + comp;
      let high = max - comp;

      for (i = 0; i < data.length; i++) {
        var val = data[i];
        if (val < low) {
          colors.push(colorLow);
        } else if (val >= low && val <= high)
          colors.push(colorMid);
        else if (val > high) {
          colors.push(colorHigh);
        }
      }
      return colors;
    }
  }
};


//merge geometry and data
export function addProperties(geojson, data, areaType) {
  let newArr = _.map(data, function (item) {
    return {
      areaname: item.area.area_name,
      areaid: item.area.area_id,
      dataValue: Number(item.per_data_value),
      indicatorType: item.indicator.type,
    }
  });
  let mergedGeoJson = _.map(geojson, function (item) {
    // if (areaType === 'State')
    return _.assign(item, _.find(newArr, ['areaid', item.properties.area_id]));
    // if (areaType === 'District')
    //   return _.assign(item, _.find(newArr, ['areaid', item.properties.area_id]));

  });
  return mergedGeoJson;
};

export function setAreaChartData(dataSort, area, toggleValue) {
  let chartData = [];

  let mycolour = ['#5BC0DE', '#00439D', '#FF6347', '#6E260E', '#808000']
  for (var i = 0; i < area.length; i++) {
    // let mycolour = '#' + Math.floor(Math.random() * 16777215).toString(16);
    let iVal = dataSort.filter(f => f.area.area_id == area[i]);
    let area_name;
    if (iVal != '')
      area_name = iVal.map(x => x.area)[0].area_name;

    let filterValue;

    if (toggleValue === 'count')
      filterValue = iVal.map(x => x.num_data_value);
    else
      filterValue = iVal.map(x => x.per_data_value);

    chartData[i] = {
      data: filterValue,
      fillColor: mycolour[i],
      backgroundColor: mycolour[i],
      borderWidth: 2,
      borderColor: mycolour[i],
      label: area_name,
    }
  }

  return chartData;

};

export function setIndicatorChartData(dataSort, indicator, toggleValue) {
  let chartData = [];
  let mycolour = ['#5BC0DE', '#00439D', '#FF6347', '#6E260E', '#808000']
  for (var i = 0; i < indicator.length; i++) {
    // let mycolour = '#' + Math.floor(Math.random() * 16777215).toString(16);
    let iVal = dataSort.filter(f => f.indicator.indicator_id == indicator[i]);
    let indicatorName;
    if (iVal != '')
      indicatorName = iVal.map(x => x.indicator)[0].label;

    let filterValue;

    if (toggleValue === 'count')
      filterValue = iVal.map(x => x.num_data_value);
    else
      filterValue = iVal.map(x => x.per_data_value);

    chartData[i] = {
      data: filterValue,
      fillColor: mycolour[i],
      backgroundColor: mycolour[i],
      borderWidth: 2,
      borderColor: mycolour[i],
      label: indicatorName,
    }
  }
  return chartData;
}

export function setFYChartData(dataSort, fyear, toggleValue) {
  let chartData = [];
  let mycolour = ['#5BC0DE', '#00439D', '#FF6347', '#6E260E', '#808000']
  for (var i = 0; i < fyear.length; i++) {
    // let mycolour = '#' + Math.floor(Math.random() * 16777215).toString(16);
    let iVal = dataSort.filter(f => f.financial_year.fy_id == fyear[i]);
    let fy_name;
    if (iVal != '')
      fy_name = iVal.map(x => x.financial_year)[0].fy_name;

    let filterValue;

    if (toggleValue === 'count')
      filterValue = iVal.map(x => x.num_data_value);
    else
      filterValue = iVal.map(x => x.per_data_value);

    chartData[i] = {
      data: filterValue,
      fillColor: mycolour[i],
      backgroundColor: mycolour[i],
      borderWidth: 2,
      borderColor: mycolour[i],
      label: fy_name,
    }
  }
  return chartData;
}

export function axisTitle(indicatorCategory, toggleValue, indicatorId, indicatorVariant) {
  if (indicatorCategory === 1) {
    if (toggleValue === 'count' || indicatorVariant === 'Numeric')
      return 'Number of Pregnant Women';
    else
      return '% of Pregnant Women';
  } else if ([3, 4].includes(indicatorCategory)) {
    if (toggleValue === 'count')
      return 'Number of Children';
    else
      return '% of Children';
  } else if (indicatorCategory === 5) {
    if ([42].includes(indicatorId)) {
      if (toggleValue === 'count')
        return 'Number of Patients';
      else
        return 'Rate (per Lakh Census 2011 pop.)';
    }
    if (indicatorId === 45) {
      if (toggleValue === 'count')
        return 'Number of Deaths';
      else
        return 'Rate (per Lakh Census 2011 pop.)';
    }
  } else if (indicatorCategory === 2) {
    if (indicatorId === 9) {
      if (toggleValue === 'count')
        return 'Number of Preterm Birth';
      else
        return 'Rate (per 1000 Live Births)';
    } else if (indicatorId === 10) {
      if (toggleValue === 'count')
        return 'Number of Still Birth';
      else
        return 'Rate (per 1000 Total Births)';
    } else if ([37, 38, 39].includes(indicatorId)) {
      if (toggleValue === 'count')
        return 'Number of Newborns';
      else
        return '% of Newborns';
    } else if (indicatorId === 41) {
      if (toggleValue === 'count')
        return 'No. of EO-Complications';
    }
    else {
      if (toggleValue === 'count' || indicatorVariant === 'Numeric')
        return 'Number of Deliveries';
      else
        return '% of Deliveries';
    }
  }
}

export function getSelectedAreaMap(geojsonMap, area, state, project, areaType, areaDetails) {
  let areaName = areaDetails.filter(f => f.area_id == area)[0].area_id;
  let geojson = null;
  geojson = geojsonMap.filter(feature => feature.properties.area_parent_id == areaName);
  return geojson;
}

export function interpolateColors(dataLength, colorScale, colorRangeInfo) {
  var { colorStart, colorEnd } = colorRangeInfo;
  var colorRange = colorEnd - colorStart;
  var intervalSize = colorRange / dataLength;
  var i, colorPoint;
  var colorArray = [];

  for (i = 0; i < dataLength; i++) {
    colorPoint = calculatePoint(i, intervalSize, colorRangeInfo);
    colorArray.push(colorScale(colorPoint));
  }

  return colorArray;
}

export function calculatePoint(i, intervalSize, colorRangeInfo) {
  var { colorStart, colorEnd, useEndAsStart } = colorRangeInfo;
  return (useEndAsStart
    ? (colorEnd - (i * intervalSize))
    : (colorStart + (i * intervalSize)));
}

export function decimalPrecision(d) {
  let oneDecimel;
  if (typeof d !== 'undefined') {
    // if(graphUnit !== 'Percent'){
    //   oneDecimel = fmt.format(d);
    // }
    // else {
    oneDecimel = fmt.formatFixed(d, 1)
    // }
    return oneDecimel;
  }
}

export function getIndicatorArray(indicatorDropdown, indiVal, toggleValue) {
  if (toggleValue === 'count') {
    for (let i = 0; i < indiVal.length; i++) {
      let found_1 = indicatorDropdown.filter(e => e.indicator_id === indiVal[i]).variant;
      if (found_1 === 'Percent') {
        indiVal = indiVal.filter(function (item) { return item !== indiVal[i] })
      }
    }
  } else {
    for (let i = 0; i < indiVal.length; i++) {
      let found_1 = indicatorDropdown.filter(e => e.indicator_id === indiVal[i]).variant;

      if (found_1 === 'Numeric') {
        indiVal = indiVal.filter(function (item) { return item !== indiVal[i] })
      }
    }
  }

  return indiVal;

}



export function downloadableCSV(heading, zipData) {
  var content = "data:text/csv;charset=utf-8,";

  var dataRows = zipData.map(function (columnValues, index) {
    return columnValues.join(",");
  });

  content += heading + "\n" + dataRows.join("\n");

  return encodeURI(content);
}



