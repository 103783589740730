import React, { Component, useRef, useEffect } from 'react'
//import {Chart, BarElement, LinearScale, CategoryScale} from 'chart.js'
import { Line } from 'react-chartjs-2'
import './chart.css'
import { axisTitle, setIndicatorChartData, getIndicatorArray } from '../util.js'
import SideChartNav from './SideChartNav';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import fmt from 'indian-number-format';


export const IndicatorCompareLineChart = ({ lChartData, indicator, fyear, toggleValue, indicatorDropdown }) => {
    const componentRef = useRef();
    let indicator_2 = indicator;
    let indiArray = getIndicatorArray(indicatorDropdown, indicator_2, toggleValue);

    let table = [], axisLabel;
    let canvasRef = useRef(null);
    const indicatorName = lChartData[0].indicator.label;

    const dataSort = lChartData.sort(function (a, b) {
        return a.financial_year.fy_id - b.financial_year.fy_id || a.month.month_id - b.month.month_id;
    });

    let graphTitle;
    if (fyear === 'All') {
        graphTitle = `${lChartData[0].area.area_name}, FY: 2017-2021`;
    }
    else {
        graphTitle = `${lChartData[0].area.area_name}, FY: ${lChartData[0].financial_year.fy_name}`;
    }

    let chartData = setIndicatorChartData(dataSort, indiArray, toggleValue);

    let x = dataSort.filter(f => f.indicator.indicator_id == indiArray[0]);

    if (toggleValue === 'count')
        axisLabel = 'Numbers / Count';
    else
        axisLabel = 'Percentage';

    if (x) {
        for (var i = 0; i < x.length; i++) {
            table.push({
                area: x[i].month.month_name,
                data: fmt.format(x[i].num_data_value)
            })
        }
    };

    var data = {
        labels: x.map(x => x.financial_year.fy_name + ":" + x.month.month_name),
        datasets: chartData,
    };

    var options = {
        scales: {
            x: {
                // grid: {
                //     display: false
                // },
                ticks: {
                    color: '#000000',
                    font: {
                        size: 12
                    },
                    callback: function (label) {
                        let realLabel = this.getLabelForValue(label)
                        var year = realLabel.split(":")[0];
                        var month = realLabel.split(":")[1];
                        return month;
                    }
                }
            },
            xAxis2: {
                // grid: {
                //     display: false
                // },
                type: "category",
                gridLines: {
                    drawOnChartArea: false, // only want the grid lines for one axis to show up
                },
                ticks: {
                    color: '#000000',
                    font: {
                        size: 12
                    },
                    callback: function (label) {
                        let realLabel = this.getLabelForValue(label)
                        var year = realLabel.split(":")[0];
                        var month = realLabel.split(":")[1];
                        if (month === "Oct") {
                            return year;
                        } else {
                            return "";
                        }
                    }
                }
            },
            y: {
                // grid: {
                //     display: false
                // },
                title: {
                    display: true,
                    text: axisLabel,
                    color: '#000000',
                    font: {
                        size: 12
                    }

                },
                ticks: {
                    color: '#000000',
                    font: {
                        size: 12
                    }
                }
            }
        },
        plugins: {
            datalabels: {
                display: false
            },
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    color: '#000000',
                    font: {
                        size: 16,
                    },
                    padding: 20,

                }
            },

            title: {
                display: true,
                color: '#000000',
                font: {
                    size: 18
                },
            },
            tooltip: {
                titleFont: {
                    size: 12
                },
                bodyFont: {
                    size: 12
                },
            },
            hover: {
                mode: 'nearest',
                intersect: true
            }

        }
    };

    const screen = useFullScreenHandle();

    const reportChange = (state, handle) => {
        if (state === true) {
            if (document.getElementById('lineChart').parentElement.clientWidth > 780) {
                document.getElementById('lineChartTitle').setAttribute('style', 'font-size: 1.5rem;');
                document.getElementById('lineChartDiv').setAttribute('style', 'padding-top:3.5rem;');
                canvasRef.current.options.scales.x.ticks.font.size = 20;
                canvasRef.current.options.scales.xAxis2.ticks.font.size = 20;
                canvasRef.current.options.scales.y.ticks.font.size = 20;
                options.plugins.tooltip.bodyFont.size = 20;
                options.plugins.tooltip.titleFont.size = 20;
                options.plugins.legend.labels.font.size = 24;
                canvasRef.current.options.scales.y.title.font.size = 20;
                document.getElementById('lineChart').setAttribute('style', 'padding:20px')
            }
        }
        else {
            document.getElementsByClassName("graph-title")[0].setAttribute('style', 'font-size: .875rem');
            document.getElementById('lineChartDiv').setAttribute('style', 'padding-top:0px;');
            canvasRef.current.options.scales.x.ticks.font.size = 12;
            canvasRef.current.options.scales.xAxis2.ticks.font.size = 12;
            canvasRef.current.options.scales.y.ticks.font.size = 12;
            options.plugins.tooltip.bodyFont.size = 12;
            options.plugins.tooltip.titleFont.size = 12;
            options.plugins.legend.labels.font.size = 12;
            canvasRef.current.options.scales.y.title.font.size = 12;
            document.getElementById('lineChart').setAttribute('style', 'padding:0px')

        }
    };

    return (
        <>
            <FullScreen className="my-fullscreen w-full bg-white h-full" handle={screen} onChange={reportChange}>
                <div class="relative w-full h-full">
                    <div className="block absolute w-auto max-h-max left-15 right-5" style={{ zIndex: 2 }}>
                        <SideChartNav id="lineChart" screen={screen} title={graphTitle} table={table} componentRef={componentRef} />
                    </div>
                    <div className='relative w-full' id="lineChart" ref={componentRef}>
                        <div className="absolute right-5 left-5 mx-10 w-auto">
                            <div className="text-center font-bold graph-title" id='lineChartTitle'>{`${graphTitle}`}</div>
                        </div>
                        <div className='relative w-full' id='lineChartDiv'>
                            <Line data={data} options={options} style={{ position: 'relative', height: '40vh', width: '80vw' }} ref={canvasRef} />
                        </div>
                    </div>
                </div>
            </FullScreen>
        </>
    )

}

export default IndicatorCompareLineChart