import { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const baseURL = "https://hmis-backend.communitygis.in";
// const baseURL = "http://127.0.0.1:4000";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
    const [authTokens, setAuthTokens] = useState(() =>
        localStorage.getItem("authTokens")
            ? JSON.parse(localStorage.getItem("authTokens"))
            : null
    );
    const [user, setUser] = useState(() =>
        localStorage.getItem("authTokens")
            ? localStorage.getItem("authTokens")
            : null
    );
    const [loading, setLoading] = useState(true);

    const history = useNavigate();

    const loginUser = async (username, password) => {
        const result = await axios.post(
            `${baseURL}/login`,
            { username: username, password: password },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
        );

        const data = result.data;

        if (result.status === 200) {
            setAuthTokens(data);

            setUser(data);
            localStorage.setItem("authTokens", JSON.stringify(data));
            window.location = "/dashboard";
            // history("/dashboard")
        } else {
            alert("Something went wrong!");
        }
    };

    const updateToken = async () => {
        try {
            const result = await axios.post(
                `${baseURL}/verifyToken`,
                { id: authTokens ? authTokens.id : null, token: authTokens ? authTokens.token : null },
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }
            );

            const data = result.data;

            if (result.status === 200) {
                setAuthTokens(data);
                setUser(data);
                localStorage.setItem("authTokens", JSON.stringify(data));
            }
            else {
                logoutUser();
            }
        } catch (e) {
            logoutUser();
            console.log(e);
        }

        if (loading) {
            setLoading(false)
        }

    }

    const registerUser = async (username, password, password2) => {
        const response = await fetch("https://127.0.0.1:8000/api/register/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                username,
                password,
                password2
            })
        });
        if (response.status === 201) {
            history("/login");
        } else {
            alert("Something went wrong!");
        }
    };

    const logoutUser = () => {
        setAuthTokens(null);
        setUser(null);
        localStorage.removeItem("authTokens");
        history("/");
    };

    const contextData = {
        user,
        setUser,
        authTokens,
        setAuthTokens,
        registerUser,
        loginUser,
        logoutUser
    };

    useEffect(() => {
        if (loading) {
            updateToken();
        }
        let fourMinutes = 1000 * 60 * 4;
        let interval = setInterval(() => {
            if (authTokens) {
                updateToken();
            }
        }, fourMinutes)
        return () => clearInterval(interval)

    }, [authTokens, loading]);

    return (
        <AuthContext.Provider value={contextData}>
            {loading ? null : children}
        </AuthContext.Provider>
    );
};