import React, { useState, useEffect, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import useAxios from '../pages/useAxios'
import AuthContext from '../context/AuthContext'

export default function SideMenu() {
    const [categories, setCategories] = useState([]);
    const [indicators, setIndicators] = useState([]);
    const api = useAxios();
    let { user } = useContext(AuthContext);

    useEffect(() => {
        const getCategories = async () => {
            const result = await api.get('/categories');

            let filterCategory = result.data.filter(c => [1, 2, 4, 9, 10].includes(c.category_id));

            if (filterCategory.length > 0) {
                setCategories([...categories, ...filterCategory]);
            }
        };

        const getIndicators = async () => {
            const result = await api.get('/indicators');

            let filterIndicator = result.data.filter(i => (i.indicator_id < 37 && i.indicator_id != 29));

            if (filterIndicator.length > 0) {
                setIndicators([...indicators, ...filterIndicator]);
            }
        };

        getCategories();
        getIndicators();
    }, []);
    return (
        <React.Fragment>
            <aside class="col-lg-2 bg-cyan-900 hmis-side">
                <nav class="sidebar  py-2 mb-4">
                    <ul class="nav flex-column" id="nav_accordion">
                        <li class="nav-item">
                            {/* Dynamic Menu Generation */}
                            {categories.map((category,) =>
                                <li className="nav-item" key={category.category_id}>
                                    <a className="nav-link" data-bs-toggle="collapse" data-bs-target={`#menu_item${category.category_id}`} href="#" aria-expanded="false">
                                        <i className="bi small bi-caret-right-fill"></i> {category.category_name} </a>
                                    <ul id={`menu_item${category.category_id}`} className="submenu collapse" data-bs-parent="#nav_accordion">
                                        {indicators.map((indicator) =>
                                            indicator.category === category.category_id && <li className="nav-link"><NavLink to={`/dashboard/${indicator.name}`} className={({ isActive }) => (isActive ? 'active' : 'inactive')} key={indicator.name}>{indicator.label}</NavLink></li>
                                        )}
                                    </ul>
                                </li>
                            )}
                        </li>
                    </ul>
                </nav>
            </aside>
        </React.Fragment>
    )
}
