import React, { useRef, useState, useEffect } from 'react';
import _ from 'lodash';
import './chart.css';
import { decimalPrecision, addProperties, getSelectedAreaMap } from '../util.js';
import useResizeObserver from '../useResizeObserver.js';
import { jsonPath } from '../contents/jsonPath';
import { geoMercator, format, geoPath, scaleQuantize, select, text, scaleThreshold } from 'd3';
import { feature } from 'topojson';
import SideChartNav from './SideChartNav';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { legendColor, legendHelpers } from 'd3-svg-legend';
import $ from 'jquery';

export const MapHolder = ({ barChartData, areaType, area, state, district, indicatorType, indicatorVariant, areaDetails, minVal, maxVal, toggleValue }) => {
  // console.log(toggleValue, "toggleValue")
  let fyID = barChartData[0].financial_year.fy_id;
  let fyName = barChartData[0].financial_year.fy_name;
  let textLabel, TextValue = null;

  let fetchMap;

  if (areaType === 'State' && fyID < 4) {
    fetchMap = jsonPath.filter(f => f.type === 'StateWoLdkh')[0].path;
  } else {
    fetchMap = jsonPath.filter(f => f.type === areaType)[0].path;
  }

  const indicatorName = barChartData[0].indicator.label;
  const indicatorId = barChartData[0].indicator.indicator_id;

  let mapTitle = `${indicatorName}, FY: ${fyName}, Month:  ${barChartData[0].month.month_name}`;

  let districtObject = null;
  if (areaType === 'State' && fyID < 4) {
    TextValue = d => d.properties.st_abbr;
    districtObject = fetchMap.objects.india_st_wo_ldkh_topojson31052023;
  }
  else if (areaType === 'District') {
    TextValue = d => d.areaname;
    districtObject = fetchMap.objects.india_dt_v7_hmisequi;
  }
  else if (areaType === 'State') {
    TextValue = d => d.properties.st_abbr;
    districtObject = fetchMap.objects.india_st_topojson31052023;
  }

  let features = feature(fetchMap, districtObject).features;
  // console.log("features", features)

  if (areaType !== 'State') {
    features = getSelectedAreaMap(features, area, null, null, areaType, areaDetails);
  }

  let geometry = { type: "FeatureCollection", features }
  let geojson = geometry.features;
  geojson = _.map(geojson, object => {
    return _.omit(object, ['dataValue'])
  });


  let mergedGeometry = addProperties(geojson, barChartData, areaType);

  // let dataArray = mergedGeometry.map(x => x.dataValue);
  // let legendArray = _.compact(dataArray) //Removing undefined value from dataArray
  // legendArray.sort((a, b) => a - b);

  // let newArray = legendArray.filter(n => n < 100); //filtering greater than 100 values
  // let min = Math.min(...newArray)
  // let max = Math.max(...newArray)
  // let comp = (max - min) / 3;
  // let low = min + comp;
  // let high = max - comp;
  let low = minVal;
  let high = maxVal;
  let highest = 100;

  let myColor = (v, low, high) => {
    low = Number(low);
    high = Number(high);

    if (indicatorType === 'negative') {
      if (v < low) return "#30A054"
      //green
      else if (v >= low && v <= high) return "#FFFF00";
      //yellow
      else if (v > high) return "#FF0000"; //red

    }
    else {
      if (v < low) return "#FF0000";
      //red
      else if (v >= low && v <= high) return "#FFFF00";
      //yellow
      else if (v > high) return "#30A054"; //green
    }
  };


  //let colourScale = scaleQuantize().domain([min, max]).range(["#FF0000", "#FFFF00", "#30A054"])
  let colourScale = scaleThreshold().domain([low, high, highest]).range(["#ff0000", "#ffff00", "#30A054", "#006837"]);


  if (indicatorType === 'negative') {
    colourScale = scaleThreshold().domain([low, high]).range(["#30A054", "#FFFF00", "#FF0000"]);
  }

  let windowWidth = window.screen.width;
  let windowHeight = window.screen.height;

  const svgRef = useRef();
  const wrapperRef = useRef();
  const componentRef = useRef();
  const dimensions = useResizeObserver(wrapperRef);
  let [offset] = useState(false);

  let tooltip = select("#map_tooltip").append("div")
    .attr("class", "tooltip")
    .style("opacity", 0);

  let left_offset, right_offset;

  useEffect(() => {

    const svg = select(svgRef.current);

    const legend = select(svgRef.current);
    let c2Value = d => d.dataValue;

    if (windowWidth >= 480) {
      windowWidth = windowWidth / 2;
      windowHeight = windowHeight / 2;
    } else {
      windowWidth = windowWidth + 200;
      // windowHeight = windowWidth;
    }
    const { width, height } = { width: windowWidth, height: windowHeight };

    const aspect = width / height;
    const adjustedHeight = Math.ceil(width / (aspect)) + 50;

    if (offset) {
      left_offset = 0;
      right_offset = 0;
    } else {
      left_offset = width;
      right_offset = adjustedHeight / 2;
    }

    svg.selectAll("*").remove();
    svg.attr("preserveAspectRatio", "xMinYMin meet")
      .attr("viewBox", `0 0 ${width} ${adjustedHeight}`)


    const onMouseMove = (event, d) => {
      if (toggleValue === 'percent') {
        if (typeof c2Value(d) != 'undefined') {
          tooltip.style("opacity", 0);
          tooltip.style("opacity", .9);
          tooltip.html("<b>" + d.areaname + "</b><br><b></b>" + "<b>" + decimalPrecision(c2Value(d)) + "</b>")
            .style("left", event.offsetX + "px")
            .style("top", event.offsetY + 20 + "px")
            // .style("left", event.clientX - left_offset + "px")
            // .style("top", event.clientY - right_offset + "px")
            .style("font-size", "0.9rem");
          tooltip.style('visibility', 'visible');
        }
        else {
          tooltip.style("opacity", 0);
          tooltip.style("opacity", .9);
          tooltip.html("<b>" + d.properties.district + "</b><br><b></b>")
            .style("left", event.offsetX + "px")
            .style("top", event.offsetY + 20 + "px")
            .style("font-size", "0.9rem");
          tooltip.style('visibility', 'visible');
        }
      } else {
        tooltip.style("opacity", 0);
        tooltip.style("opacity", .9);
        tooltip.html("<b>" + d.areaname + "</b><br><b></b>")
          .style("left", event.offsetX + "px")
          .style("top", event.offsetY + 20 + "px")
          .style("font-size", "0.9rem");
        tooltip.style('visibility', 'visible');
      }
    };

    let projection;
    // let projection = geoMercator().fitSize([width, adjustedHeight / 1.085], geometry);

    if (area === 9 || area === 28) { // adjustment for Daman & Diu, Puducherry
      projection = geoMercator().fitSize([width / 1.5, adjustedHeight / 1.2], geometry);
    }
    else {
      projection = geoMercator().fitSize([width / 1.1, adjustedHeight / 1.16], geometry);
    }

    const pathGenerator = geoPath(projection);

    svg
      .selectAll(".polygon")
      .attr("width", width)
      .attr("height", height)
      .data(mergedGeometry)
      .join("path").attr("class", "polygon")
      .style("fill", d => {
        if (typeof c2Value(d) != "undefined") {
          if (c2Value(d) >= 100) {
            if (d.indicatorType == "positive")
              return "#006837";
            else
              return "#FF0000";
          }
          else {
            return myColor(c2Value(d), low, high);
          }
        }
        else
          return "#A9A9B0";
      })
      .on("mousemove", (i, d) => onMouseMove(i, d))
      .on("mouseout", function (d) {
        tooltip
          .style("opacity", 0);
      })
      .attr("d", feature => pathGenerator(feature))
      .attr('transform', `translate(50,60)`);

    // svg.append("text")
    //   .attr("x", (width / 2))
    //   .attr("y", height - adjustedHeight + 33)
    //   .attr("text-anchor", "middle")
    //   .style("font-size", "1.3rem")
    //   .style("font-weight", "bold")
    //   .text(indicatorName);

    // let legendRect, legendLabel; //No Data legend and Label => Grey Color

    if (width > 680) {
      svg.append("g")
        .attr("class", "legendQuant")
        .attr("transform", `translate(${width - (width - 10)},${adjustedHeight - 200})`)

      // legendRect = svg.append("rect")
      //   .attr("transform", `translate(${width - (width - 10)},${adjustedHeight - 117})`)

      // legendLabel = svg.append("text")
      //   .attr("transform", `translate(${width - (width - 60)},${adjustedHeight - 107})`)
    }
    else {
      svg.append("g")
        .attr("class", "legendQuant")
        .attr("transform", `translate(${width - (width - 10)},${adjustedHeight - 160})`)

      // legendRect = svg.append("rect")
      //   .attr("transform", `translate(${width - (width - 10)},${adjustedHeight - 77})`)

      // legendLabel = svg.append("text")
      //   .attr("transform", `translate(${width - (width - 60)},${adjustedHeight - 67})`)
    }

    textLabel = svg.append("g")
      .selectAll("text")
      .data(mergedGeometry)
      .enter()
      .append("text")
      // .html(d => {
      //   return `${TextValue(d)}: ${c2Value(d)}`;
      // })
      .attr("x", function (d) {
        return (pathGenerator.centroid(d)[0] + 50);
      })
      .attr("y", function (d) {
        return (pathGenerator.centroid(d)[1] + 60);
      })
      // .attr("transform", function (d) {
      //   return "translate(" + pathGenerator.centroid(d) + ")";
      // })
      .call(
        text => text.append('tspan')
          .attr("class", "text-label")
          .attr('font-size', ".563rem")
          .attr("text-anchor", "middle")
          .style('font-weight', 'bold')
          // .attr('dx', 0)
          .text(d => TextValue(d))
      )
      .call(
        text => text.append('tspan')
          .attr("class", "text-label")
          .attr('dy', 9)
          .attr('dx', -12)
          .attr("text-anchor", "middle")

          .attr('font-size', ".563rem")
          .style('font-weight', 'bold')

          .text(d => Math.round(c2Value(d)))
      )




    let formatter = format(".1f");
    let myLegend;
    // console.log(colourScale, "colourScale")

    if (indicatorId === 9) {
      myLegend = legendColor()
        .labelFormat(formatter)
        .labels(legendHelpers.thresholdLabels)
        .title('Rate (per 1000 LB)')
        .titleWidth(180)
        .scale(colourScale);

      // legendRect.attr("text-anchor", "middle")
      //   .style("fill", "rgb(169, 169, 176)")


      // legendLabel.style("text-anchor", "middle")
      //   .text("No Data")
      //   .style("color", "black")
      //   .style("font-weight", "bold");
    } else if (indicatorId === 10) {
      myLegend = legendColor()
        .labelFormat(formatter)
        .labels(legendHelpers.thresholdLabels)
        .title('Rate (per 1000 TB)')
        .titleWidth(180)
        .scale(colourScale);
    } else if ([42, 45].includes(indicatorId)) {
      myLegend = legendColor()
        .labelFormat(formatter)
        .labels(legendHelpers.thresholdLabels)
        .title('Rate (per Lakh Census 2011 pop.)')
        .titleWidth(180)
        .scale(colourScale);
    } else {
      myLegend = legendColor()
        .labelFormat(formatter)
        .labels(legendHelpers.thresholdLabels)
        .title('Percent')
        .titleWidth(180)
        .scale(colourScale);


      // legendRect.attr("text-anchor", "middle")
      //   .style("fill", "rgb(169, 169, 176)")


      // legendLabel.style("text-anchor", "middle")
      //   .text("No Data")
      //   .style("color", "black")
      //   .style("font-weight", "bold");
    }

    svg.select(".legendQuant")
      .call(myLegend)
    // .style("font-size", "0.9rem");

    //Map visibility for numeric data Elements
    if (null === barChartData || barChartData.length === 0 || indicatorVariant === 'Numeric' || toggleValue === 'count') {
      svg.selectAll(".text-label").remove();
      svg
        .selectAll(".polygon")
        .attr("width", width)
        .attr("height", height)
        .join("path").attr("class", "polygon")
        .style("fill", "#A9A9B0")

      svg.append("text").text("This is a numeric data element, hence map is Not Applicable")
        .style("text-anchor", "middle")
        .style("font-weight", "bold")
        .style("fill", "red")
        .attr('transform', `translate(${width / 2}, ${height / 2})`);
    };

    //Visibility for Overreporting Note
    let arrayList = barChartData.map(x => x.per_data_value);
    if (indicatorType === 'positive' && (indicatorVariant === 'Both' || indicatorVariant === 'Percent') && Math.max(...arrayList) > 100)
      $("#overreporting_note").show();
    else
      $("#overreporting_note").hide();




  }, [barChartData, dimensions, geometry])

  const screen = useFullScreenHandle();

  let table = [];
  if (barChartData) {
    for (var i = 0; i < barChartData.length; i++) {
      table.push({
        area: barChartData[i].area.area_name,
        data: decimalPrecision(barChartData[i].per_data_value)
      })

    }
  }


  const reportChange = (state, handle) => {
    if (state === true) {
      tooltip.style("opacity", 0);
      document.getElementsByClassName("my-fullscreen")[0].setAttribute('style', 'overflow: auto !important');
      if (document.getElementById('map_tooltip').parentElement.clientWidth > 780) {
        document.getElementsByClassName("graph-title")[0].setAttribute('style', 'font-size: 1.5rem;');
        document.getElementById("overreporting_note").setAttribute('style', 'font-size: 1.2rem;');
        document.getElementById('svgMap').setAttribute('style', 'padding-top:20px');
      }
    }
    else {
      tooltip.style("opacity", 0);
      document.getElementsByClassName("my-fullscreen")[0].setAttribute('style', 'overflow: hidden !important');
      document.getElementsByClassName("graph-title")[0].setAttribute('style', 'font-size: 1rem;');
      document.getElementById("overreporting_note").setAttribute('style', 'font-size: .7rem;');
      document.getElementById('svgMap').setAttribute('style', 'padding-top:10px');

    }
  };


  return (
    <>
      <FullScreen className="my-fullscreen w-full bg-white h-full" handle={screen} onChange={reportChange}>

        <div class="relative w-full h-full" >
          <div className="block absolute w-auto max-h-max left-15 right-5" style={{ zIndex: 2 }}>
            <SideChartNav id="svgMap" screen={screen} title={mapTitle} table={table} componentRef={componentRef} />
          </div>
          <div className='relative  w-full' id="svgMap" ref={componentRef}>
            <div className="absolute right-2 left-2 md:right-5 md:left-5 mx-10 lg:mx-8 w-auto">
              <div className="graph-title text-center font-bold md:font-semibold text-sm  lg:font-bold">{`${mapTitle}`}</div>
              <div className="text-center font-thin text-xs md:text-xs lg:text-xs" id="overreporting_note">
                Note:Above 100% has been considered green, watch-out for overreporting
              </div>
            </div>

            <div id="map_tooltip" className='align-middle w-full h-full' ref={wrapperRef}>
              <svg ref={svgRef}
                className="w-full align-center bg-white border-black border-dashed object-scale-down sm:pt-4"></svg>

            </div>
          </div>
        </div>
      </FullScreen>
    </>
  )

}

export default MapHolder