import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../context/AuthContext';

export default function Header() {
    const { user, logoutUser } = useContext(AuthContext);

    const [navbar, setNavbar] = useState(false);

    return (
        <React.Fragment>
            <header>
                <nav className="w-full bg-cyan-900 shadow">
                    <div className="justify-between px-4 lg:max-w-full md:items-center md:flex md:px-8 mx-0">
                        <div>
                            <div className="flex items-center justify-between py-3 md:py-5 md:block">
                                <Link to="/" className="flex items-center rounded-lg hover:bg-cyan-700 no-underline">
                                    <img src="/assets/img/logo.svg" alt="Maharashtra HMIS Analyzer Logo" class="h-6 mr-3 sm:h-9" />
                                    <span class="self-center md:text-2xl text-white font-extrabold sm:text-xs whitespace-nowrap">India - HMIS Analyzer</span>
                                </Link>
                                <div className="md:hidden">
                                    <button
                                        className="p-2 text-white rounded-md outline-none focus:ring-2 focus:ring-white-200"
                                        onClick={() => setNavbar(!navbar)}
                                    >
                                        {navbar ? (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="w-6 h-6"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="w-6 h-6"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                strokeWidth={2}
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M4 6h16M4 12h16M4 18h16"
                                                />
                                            </svg>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div
                                className={`flex-1 justify-self-center md:pt-3 md:block ${navbar ? "block" : "hidden"
                                    }`}
                            >

                                {user ? (
                                    <>
                                        <ul class="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0 font-bold">
                                            <li>
                                                <Link to="/" className='block text-white no-underline py-2 pl-3 pr-3 rounded-2xl hover:bg-cyan-700'>
                                                    <img class="inline-flex pr-1 pb-2" src="/assets/icons/home.svg" />Home
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/dashboard" className='block text-white no-underline py-2 pl-3 pr-3 rounded-2xl hover:bg-cyan-700'>
                                                    <img class="inline-flex pr-1 pb-1" src="/assets/icons/dashboard.svg" />Dashboard
                                                </Link>
                                            </li>
                                            <li className='block text-white  py-2 pl-3 pr-3 rounded-2xl hover:bg-cyan-700'>
                                                <a onClick={logoutUser}>
                                                    <img class="inline-flex pr-1 pb-1" src="/assets/icons/logout.svg" />Logout</a>
                                            </li>
                                        </ul>
                                    </>
                                ) : (
                                    <>
                                        <ul class="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0 font-bold">
                                            <li>
                                                <Link to="/" className='block text-white no-underline py-2 pl-3 pr-3 rounded-2xl hover:bg-cyan-700'>
                                                    <img class="inline-flex pr-1 pb-2" src="/assets/icons/home.svg" />Home
                                                </Link>
                                            </li>
                                            <li>
                                                <a href="#" className='block text-white no-underline py-2 pl-3 pr-3 rounded-2xl hover:bg-cyan-700' data-bs-toggle="modal" data-bs-target="#modalForm">
                                                    <img class="inline-flex pr-1 pb-1" src="/assets/icons/login.svg" />Login</a>
                                            </li>
                                        </ul>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </React.Fragment>
    );
}
