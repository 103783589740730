import React, { Component, useRef, useEffect } from 'react'
//import {Chart, BarElement, LinearScale, CategoryScale} from 'chart.js'
import { Line } from 'react-chartjs-2'
import './chart.css'
import { getColorArray, setFYChartData } from '../util.js'
import SideChartNav from './SideChartNav';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import fmt from 'indian-number-format';
import Chart from 'chart.js/auto';

// Chart.register(
//   BarElement, LinearScale, CategoryScale, 
// )


export const FYCompareLineChart = ({ lChartData, fyear, toggleValue }) => {
    let windowHeight = (window.screen.height) * 0.58;
    const componentRef = useRef();
    let windowWidth = window.screen.width;
    let table = [], axisLabel;
    const canvasRef = useRef(null);

    const indicatorName = lChartData[0].indicator.label;

    let graphTitle = `${indicatorName}, ${lChartData[0].area.area_name}`;

    const dataSort = lChartData.sort(function (a, b) {
        return a.month.month_id - b.month.month_id;
    });

    let chartData = setFYChartData(dataSort, fyear, toggleValue);

    if (toggleValue === 'count')
        axisLabel = 'Numbers / Count';
    else
        axisLabel = 'Percentage';

    let x = dataSort.filter(f => f.financial_year.fy_id == fyear[0]);

    if (x) {
        for (var i = 0; i < x.length; i++) {
            table.push({
                area: x[i].month.month_name,
                data: fmt.format(x[i].num_data_value)
            })
        }
    };

    var data = {
        labels: x.map(x => x.month.month_name),
        datasets: chartData,
    };

    var options = {
        maintainAspectRatio: false,
        scales: {
            x: {
                // grid: {
                //     display: false
                // },
                ticks: {
                    color: '#000000',
                    font: {
                        size: 12,
                    }
                }
            },
            y: {
                // grid: {
                //     display: false
                // },
                title: {
                    display: true,
                    text: axisLabel,
                    color: '#000000',
                    font: {
                        size: 12
                    }
                },
                ticks: {
                    color: '#000000',
                    font: {
                        size: 12,
                    }
                }
            }
        },
        plugins: {
            datalabels: {
                display: false
            },
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    color: '#000000',
                    font: {
                        size: 16,
                    },
                    padding: 20,
                }
            },

            title: {
                display: true,
                color: '#000000',
                font: {
                    size: 18
                },
            },
            tooltip: {
                titleFont: {
                    size: 12
                },
                bodyFont: {
                    size: 12
                },
            },
            hover: {
                mode: 'nearest',
                intersect: true
            }

        }
    };

    const screen = useFullScreenHandle();
    let height, paddingValue, width;

    if (screen.active === true) {
        height = '90vh';
        paddingValue = '3rem';

    } else
        height = '60vh';

    const reportChange = (state, handle) => {
        if (state === true) {
            if (document.getElementById('lineChart').parentElement.clientWidth > 780) {

                document.getElementById('lineChartTitle').setAttribute('style', 'font-size: 1.5rem;');
                document.getElementById('lineChartDiv').setAttribute('style', 'padding-top:3.5rem;');
                canvasRef.current.options.scales.x.ticks.font.size = 24;
                canvasRef.current.options.scales.y.ticks.font.size = 24;
                options.plugins.tooltip.bodyFont.size = 24;
                options.plugins.tooltip.titleFont.size = 24;
                options.plugins.legend.labels.font.size = 24;
                canvasRef.current.options.scales.y.title.font.size = 24;
                document.getElementById('lineChart').setAttribute('style', 'padding-top:20px')
            }
        }
        else {
            document.getElementsByClassName("graph-title")[0].setAttribute('style', 'font-size: .875rem');
            document.getElementById('lineChartDiv').setAttribute('style', 'padding-top:0;');
            canvasRef.current.options.scales.x.ticks.font.size = 12;
            canvasRef.current.options.scales.y.ticks.font.size = 12;
            options.plugins.tooltip.bodyFont.size = 12;
            options.plugins.tooltip.titleFont.size = 12;
            options.plugins.legend.labels.font.size = 12;
            canvasRef.current.options.scales.y.title.font.size = 12;
            document.getElementById('lineChart').setAttribute('style', 'padding-top:0px')

        }
    };

    return (
        <>
            <FullScreen className="my-fullscreen w-full bg-white h-full" handle={screen} onChange={reportChange}>
                <div class="relative w-full h-full">
                    <div className="block absolute w-auto max-h-max left-15 right-5" style={{ zIndex: 2 }}>
                        <SideChartNav id="lineChart" screen={screen} title={graphTitle} table={table} componentRef={componentRef} />
                    </div>
                    <div className='relative w-full' id="lineChart" ref={componentRef}>
                        <div className="absolute right-5 left-5 mx-10 w-auto">
                            <div className="text-center font-bold graph-title" id='lineChartTitle'>{`${graphTitle}`}</div>
                        </div>
                        <div className='relative w-full px-24' id='lineChartDiv'>
                            <Line data={data} options={options} style={{ position: 'relative', height: height, width: '80vw' }} ref={canvasRef} />
                        </div>
                    </div>
                </div>
            </FullScreen>
        </>
    )

}

export default FYCompareLineChart