import React, { useContext } from 'react'

import Header from '../components/Header'
import Footer from '../components/Footer'
import Login from '../components/Login'
import AuthContext from '../context/AuthContext'
import $ from 'jquery';
import 'bootstrap';

export default function Home() {
    const { user, logoutUser } = useContext(AuthContext);

    $(document).ready(function ($) {
        $(".loginModal").on("click", function (event) {
            if (user === null)
                window.$('#modalForm').modal('show');
            else
                window.location = "/";
        });
    });

    return (
        <React.Fragment>
            <Header />
            <main>
                <div class="modal fade" id="modalForm" tabindex="-1" aria-labelledby="loginform" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title hmis-black" id="loginform">
                                    <img class="login-dashboard-logo inline" src="assets/img/logo.svg" alt="Logo"></img>
                                    Dashboard Login
                                </h4>
                            </div>
                            <div class="modal-body">
                                <Login />
                            </div>
                            <div class="modal-footer d-block">
                                <p class="float-start hmis-sub-p">Find us here</p>
                                <span class="float-end">
                                    <img class="hmis-modal-img inline" src="assets/img/linkedin.svg" alt="Linkedin" />
                                    <img class="hmis-modal-img inline" src="assets/img/facebook.svg" alt="Facebook" />
                                    <img class="hmis-modal-img inline" src="assets/img/twitter.svg" alt="Twitter" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row hmis-row px-0 mx-0 my-3">
                    <div class="col-md-12">
                        <h2 class="text-3xl font-bold text-black md:text-2xl lg:text-3xl">India - HMIS Data Analysis and Visualization</h2>
                        <p>Welcome to HMIS Analyzer. This is a web portal that represents data analysis and visualization of HMIS data for maternal and child health parameters. The information available on this portal is acquired from HMIS database available at public domain for four financial years from April 2017 to March 2021. The purpose of this analyzer is to identify and monitor key issues to improve maternal and child health status at national, state, district and block/taluka level.
                        </p>
                    </div>
                </div>
                <div class="row hmis-row mx-0">
                    <div class="col-md-3 px-5">
                        <a class="loginModal no-underline" href="#">
                            <img class="home-indicator-logo" src="assets/img/pregnancy.svg" alt=""></img>
                            <h4>Pregnancies</h4>
                        </a>
                        <p>This section provides the information on the parameters associated with registered pregnant women under Antenatal Care Services</p>
                    </div>
                    <div class="col-md-3 px-5">
                        <a class="loginModal no-underline" href="#">
                            <img class="home-indicator-logo" src="assets/img/deliveries.svg" alt=""></img>
                            <h4>Deliveries</h4>
                        </a>
                        <p>It includes child birth related data elements and indicators such as home & institutional deliveries, C-Section deliveries, still births & preterm births</p>
                    </div>
                    <div class="col-md-3 px-5">
                        <a class="loginModal no-underline" href="#">
                            <img class="home-indicator-logo" src="assets/img/child_immunisation.svg" alt=""></img>
                            <h4>Child Immunisation</h4>
                        </a>
                        <p>Coverage of different doses of vaccines under child immunization such as BCG, Vitamin K, Pentavalent, Measles etc. is covered in this category</p>
                    </div>
                    <div class="col-md-3 px-5">
                        <a class="loginModal no-underline" href="#">
                            <img class="home-indicator-logo" src="assets/img/child_disease.svg" alt=""></img>
                            <h4>Child Disease</h4>
                        </a>
                        <p>Incidence and burden of different childhood diseases can be observed and analyzed through different visualization techniques in this segment</p>
                    </div>
                </div>
            </main>
            <Footer />
        </React.Fragment>
    )
}
