
import axios from "axios";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";

const baseURL = "https://hmis-backend.communitygis.in";
// const baseURL = "http://127.0.0.1:4000";

const useAxios = () => {
  const { authTokens, setUser, setAuthTokens } = useContext(AuthContext);

  const axiosInstance = axios.create({
    baseURL: baseURL,
    headers: {
      "Content-Type": 'application/x-www-form-urlencoded',
      "Authorization": `${JSON.stringify(authTokens)}`
    },
  });

  axiosInstance.interceptors.request.use(async req => {
    try {
      const response = await axios.post(`${baseURL}/verifyToken`, { id: authTokens ? authTokens.id : null, token: authTokens ? authTokens.token : null },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });

      localStorage.setItem("authTokens", JSON.stringify(response.data));

      setAuthTokens(response.data);
      setUser(response.data);
      return req;
    }
    catch (e) {
      console.log(e);
    }
  });

  return axiosInstance;
};

export default useAxios;