import React, { Component, useState, useEffect, useRef, useContext } from 'react'
import { fetchData } from '../util.js'
import useAxios from '../pages/useAxios';
import AuthContext from '../context/AuthContext.js';


export const FilterForm = ({ fyear, state, district, month, area, indicator, areaType, indicatorCategory, stateDropdown, districtDropdown, fyDropdown, monthDropdown, setAreaType, setArea, setMonth, setFyear, setState, setDistrict, setbarChartData, setLineChartData, setTableData, setQualityData, isSelected, setIsSelected }) => {

    const api = useAxios();
    let { authtokens, logoutUser } = useContext(AuthContext)

    const handleFinancialYear = async (e) => {
        setIsSelected(false);
        const fyearSelected = e.target.value;

        setFyear(fyearSelected);
        await fetchData(fyearSelected, month, area, indicator, areaType, indicatorCategory, setbarChartData, setLineChartData, setTableData, setQualityData, api, logoutUser);
        setIsSelected(true);
    };

    const handleMonthChange = async (e) => {
        setIsSelected(false);
        let monthSelected = e.target.value;
        setMonth(monthSelected);

        await fetchData(fyear, monthSelected, area, indicator, areaType, indicatorCategory, setbarChartData, setLineChartData, setTableData, setQualityData, api, logoutUser);
        setIsSelected(true);
    };

    const handleStateChange = async (e) => {
        setIsSelected(false);
        const districtName = e.target.value;
        let areaSelected = 1
        let areaTypeSelected = 'State';
        if (districtName == 1) {
            setAreaType('State');
            setArea(1);
            setDistrict(774);
        }
        else {
            setAreaType('District');
            areaSelected = districtName;
            areaTypeSelected = 'District';
            setArea(districtName);
        }
        setState(districtName);

        await fetchData(fyear, month, areaSelected, indicator, areaTypeSelected, indicatorCategory, setbarChartData, setLineChartData, setTableData, setQualityData, api, logoutUser);
        setIsSelected(true);
    };

    const handleDistrictChange = async (e) => {
        setIsSelected(false);
        const subdtName = e.target.value;
        let areaSelected = state;

        let areaTypeSelected = 'District';
        setAreaType('District');
        setArea(subdtName);

        if (subdtName == 774) {
            setAreaType('District');
            setArea(state);
        }
        else {
            setAreaType('District');
            setArea(subdtName);
            areaTypeSelected = 'District';
            areaSelected = subdtName;
        }

        setDistrict(subdtName);
        await fetchData(fyear, month, areaSelected, indicator, areaTypeSelected, indicatorCategory, setbarChartData, setLineChartData, setTableData, setQualityData, api, logoutUser);
        setIsSelected(true);
    };

    return (
        <React.Fragment>
            <form action="">
                <div class="flex flex-wrap md:flex-row">
                    <div class="flex w-full md:w-1/4">
                        <div className='flex-col w-11/12'>
                            <label for="" class="block mb-1 mt-2 md:mt-0 text-base lg:text-lg font-bold text-black">Select Financial Year</label>
                            <select name="year" id="" class="form-select bg-gray-50 border border-gray-500 text-black text-base md:text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                value={fyear}
                                onChange={handleFinancialYear}
                                disabled={!isSelected}>

                                {fyDropdown.map((year) => {
                                    return (
                                        <option value={year.fy_id}>{year.fy_name}</option>
                                    )
                                })
                                }

                            </select>
                        </div>
                    </div>
                    <div class="flex w-full md:w-1/4">
                        <div className='flex-col w-11/12'>
                            <label for="" class="block mb-1 mt-2 md:mt-0 text-base lg:text-lg font-bold text-black">Select Month</label>
                            <select name="month" id="" class="form-select bg-gray-50 border border-gray-500 text-black text-base md:text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                value={month}
                                onChange={handleMonthChange}
                                disabled={!isSelected}>
                                {monthDropdown.map((month) => {
                                    return (
                                        <option value={month.month_id}>{month.month_name}</option>
                                    )
                                })
                                }

                            </select>
                        </div>
                    </div>
                    <div class="flex w-full md:w-1/4">
                        <div className='flex-col w-11/12'>
                            <label for="" class="block mb-1 mt-2 md:mt-0 text-base lg:text-lg font-bold text-black">Select State</label>
                            <select name="state" id="" class="form-select bg-gray-50 border border-gray-500 text-black text-base md:text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                value={state}
                                onChange={handleStateChange}
                                disabled={!isSelected}>
                                {/* <option value={1}>All (India)</option> */}
                                {stateDropdown.map((data) => {
                                    return (
                                        <option value={data.area_id}>{data.area_name}</option>
                                    )
                                })
                                }
                            </select>
                        </div>
                    </div>
                    <div class="flex w-full md:w-1/4">
                        <div className='flex-col w-11/12'>
                            <label for="" class="block mb-1 mt-2 md:mt-0 text-base lg:text-lg font-bold text-black">Select District</label>
                            <select name="subdt" id="" class="form-select bg-gray-50 border border-gray-500 text-black text-base md:text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                value={district}
                                onChange={handleDistrictChange}
                                disabled={!isSelected}>
                                <option value={774}>All</option>
                                {districtDropdown.filter(district => district.area_parent_id == state).map(filtered_subdt => {
                                    return (

                                        <option value={filtered_subdt.area_id}>{filtered_subdt.area_name}</option>
                                    )

                                }
                                )
                                }
                            </select>
                        </div>
                    </div>

                </div>
            </form>
        </React.Fragment>
    )

}

export default FilterForm
