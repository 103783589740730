import React, { Component, useRef, useEffect } from 'react'
import { Bar } from 'react-chartjs-2'
import annotationPlugin from 'chartjs-plugin-annotation';
import "./chart.css"
import { getColorArray, axisTitle, setIndicatorChartData, getIndicatorArray } from '../util.js'
import SideChartNav from './SideChartNav';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Chart from 'chart.js/auto';
import fmt from 'indian-number-format';
Chart.register(annotationPlugin);

export const IndicatorCompareBarChart = ({ barChartData, indicator, toggleValue, indicatorDropdown }) => {
    const componentRef = useRef();
    let windowWidth = window.screen.width;
    let table = [], axisLabel;
    let canvasRef = useRef(null);
    let indicator_2 = indicator;
    let indiArray = getIndicatorArray(indicatorDropdown, indicator_2, toggleValue);

    const indicatorName = barChartData[0].indicator.label;

    let dataSort,
        graphTitle = `FY: ${barChartData[0].financial_year.fy_name}, Month: ${barChartData[0].month.month_name}`,
        indicatorId = barChartData[0].indicator.indicator_id;

    if (toggleValue === 'count') {
        dataSort = barChartData.sort(function (a, b) {
            return b.num_data_value - a.num_data_value;
        });
        axisLabel = 'Numbers / Count';
    }
    else {
        dataSort = barChartData.sort(function (a, b) {
            return b.per_data_value - a.per_data_value;
        });
        axisLabel = 'Percentage';
    }

    let chartData = setIndicatorChartData(dataSort, indiArray, toggleValue);

    let x = dataSort.filter(f => f.indicator.indicator_id == indiArray[0]);

    if (x) {
        for (var i = 0; i < x.length; i++) {
            table.push({
                area: x[i].area.area_name,
                data: fmt.format(x[i].num_data_value)
            })
        }
    };

    var data = {
        labels: x.map(x => x.area.area_name),
        //showTooltips: true,
        datasets: chartData,
    };

    var options = {
        // indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    color: '#000000',
                    font: {
                        size: 12
                    }
                }
            },
            y: {
                grid: {
                    display: false
                },
                title: {
                    display: true,
                    text: axisLabel,
                    color: '#000000',
                    font: {
                        size: 12
                    }

                },
                ticks: {
                    color: '#000000',
                    font: {
                        size: 12
                    }
                }
            },
        },
        plugins: {
            datalabels: {
                display: false
            },
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    color: '#000000',
                    font: {
                        size: 16,
                    },
                    padding: 20,
                }
            },
            // title: {
            //     display: true,
            //     text: indicatorName,
            //     color: '#000000',
            //     font: {
            //         size: 16
            //     },
            // },
            tooltip: {
                titleFont: {
                    size: 12
                },
                bodyFont: {
                    size: 12
                },
            },
            hover: {
                mode: 'nearest',
                intersect: true
            }


        }

    };

    const screen = useFullScreenHandle();

    const reportChange = (state, handle) => {
        if (state === true) {
            if (document.getElementById('barChart').parentElement.clientWidth > 780) {

                document.getElementById('barChartTitle').setAttribute('style', 'font-size: 1.5rem;');
                document.getElementById('barChartDiv').setAttribute('style', 'padding-top:3.5rem;');
                canvasRef.current.options.scales.x.ticks.font.size = 20;
                canvasRef.current.options.scales.y.ticks.font.size = 20;
                options.plugins.tooltip.bodyFont.size = 20;
                options.plugins.tooltip.titleFont.size = 20;
                options.plugins.legend.labels.font.size = 24;
                canvasRef.current.options.scales.y.title.font.size = 20;
                document.getElementById('barChart').setAttribute('style', 'padding:20px')
            }
        }
        else {
            document.getElementsByClassName("graph-title")[0].setAttribute('style', 'font-size: .875rem');
            document.getElementById('barChartTitle').setAttribute('style', 'font-size: .875rem;');
            document.getElementById('barChartDiv').setAttribute('style', 'padding-top:0;');
            canvasRef.current.options.scales.x.ticks.font.size = 12;
            canvasRef.current.options.scales.y.ticks.font.size = 12;
            options.plugins.tooltip.bodyFont.size = 12;
            options.plugins.tooltip.titleFont.size = 12;
            options.plugins.legend.labels.font.size = 12;
            canvasRef.current.options.scales.y.title.font.size = 12;
            document.getElementById('barChart').setAttribute('style', 'padding:0px')

        }
    };

    return (
        <>
            <FullScreen className="my-fullscreen w-full bg-white h-full" handle={screen} onChange={reportChange}>
                <div class="relative w-full h-full">
                    <div className="block absolute w-auto max-h-max left-15 right-5" style={{ zIndex: 2 }}>
                        <SideChartNav id="barChart" screen={screen} title={graphTitle} table={table} componentRef={componentRef} />
                    </div>
                    <div className='relative w-full' id="barChart" ref={componentRef}>
                        <div className="absolute right-5 left-5 mx-10 w-auto">
                            <div className="text-center font-bold graph-title" id='barChartTitle'>{`${graphTitle}`}</div>
                        </div>
                        <div className='relative w-full pt-3' id='barChartDiv'>
                            <Bar data={data} options={options} style={{ position: 'relative', height: '80vh', width: '80vw' }} ref={canvasRef} />
                        </div>
                    </div>
                </div>
            </FullScreen>
        </>
    )

}

export default IndicatorCompareBarChart