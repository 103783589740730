export const jsonPath = [
    {
        type: 'State',
        path: require('../contents/json/india_st_geojson31052023.json')
    },
    {
        type: 'StateWoLdkh',
        path: require('../contents/json/india_st_wo_ldkh_geojson31052023.json')
    },
    {
        type: 'District',
        path: require('../contents/json/india_dt_v7_hmisequi.json')
    }


]